import * as PrebidV2 from 'BaxterScript/version/web/provider/google/PrebidV2';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { GoogleAdsSlotExternal, GoogleAdsTransformedBiddersPrebid, GoogleAdsSlot } from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { Config } from 'BaxterScript/types/Config';
import {
  GoogleAdsApsConfig,
  GoogleAdsConfig,
  GoogleAdsPrebidConfig,
} from 'BaxterScript/types/ProviderSettings/GoogleAds';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig;
  const prebidSettings = providerSettings.prebid;
  return (
    !(
      (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
      (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
    ) || !baxterV2Enabled(config)
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][INIT]');
  PrebidV2.init();
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][DEPENDENCIES]');
  return PrebidV2.dependencies();
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOADED]');
  PrebidV2.loaded();
};

export const transform = (
  slotConfig: GoogleAdsPrebidConfig | GoogleAdsApsConfig
): GoogleAdsTransformedBiddersPrebid => {
  console.info('[SLOTS][GOOGLEADSPREBID][TRANSFORM]', slotConfig);
  return { sizes: PrebidV2.transform(slotConfig) };
};

const getPrebidExternal = (slot: GoogleAdsSlot): GoogleAdsSlotExternal[] => {
  const external: GoogleAdsSlotExternal[] = [];
  const bids = PrebidV2.getBids(
    slot[id].transformed.targeting,
    slot[id].providerConfig,
    slot[id].config.prebid.bidders
  );
  const sizes = slot[id].transformed.bidders?.prebid?.sizes;
  let externalBase = {};
  if (sizes?.length) {
    externalBase = {
      sizes,
      mediaTypes: {
        banner: { sizes },
      },
    };
  }
  if (bids.path) {
    external.push({
      ...externalBase,
      code: slot[id].transformed.path,
      bids: bids.path,
    });
  }
  if (bids.id) {
    external.push({
      ...externalBase,
      code: slot.innerId,
      bids: bids.id,
    });
  }

  return external;
};

export const create = (slot: GoogleAdsSlot, biddersCallback: () => void): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][CREATE]', slot);
  const { pbjs } = globalThis;
  if (pbjs) {
    const external = getPrebidExternal(slot);
    console.debug('[SLOTS][GOOGLEADSPREBID][CREATE]', slot, external);
    pbjs.que.push(() => {
      try {
        pbjs.addAdUnits(external);
        biddersCallback();
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSPREBID][CREATE]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[GOOGLEADSPREBIDCREATE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADSPREBID][CREATE] pbjs not defined`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[GOOGLEADSPREBIDCREATE]' });
    throw new Error('[SLOTS][GOOGLEADSPREBID][CREATE] pbjs not defined');
  }
};

export const load = (prebidSlots: GoogleAdsSlot[], biddersCallback: () => void) => {
  console.info('[SLOTS][GOOGLEADSPREBID][LOAD]', prebidSlots);
  const { pbjs } = globalThis;
  if (pbjs) {
    pbjs.que.push(() => {
      try {
        const slotsToLoad = prebidSlots.filter((slot) => {
          if (slot[id].state.alreadyRemoved) {
            console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] slot already removed', slot);
            newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED);
            return false;
          }
          return true;
        });
        if (!slotsToLoad.length) {
          biddersCallback();
          return;
        }
        console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] requesting bids');
        const slotCodes = [
          ...slotsToLoad.map((slot) => slot.innerId),
          ...slotsToLoad.map((slot) => slot[id].transformed.path),
        ];
        newRelicMetrics.reportMetric(NewRelicMetric.PREBID_REQUESTED_BIDS);
        pbjs.requestBids({
          adUnitCodes: slotCodes,
          bidsBackHandler: (bids, timedOut) => {
            try {
              if (timedOut) {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] timed out', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_TIMED_OUT);
              } else {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] successfully returned', bids);
                newRelicMetrics.reportMetric(NewRelicMetric.PREBID_SUCCESSFULLY_RETURNED);
              }
              const loadedSlots = slotsToLoad.filter((slot) => {
                if (slot[id].state.alreadyRemoved) {
                  console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] slot already removed', slot);
                  newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED);
                  return false;
                }
                return true;
              });
              const loadedSlotCodes = [
                ...loadedSlots.map((slot) => slot.innerId),
                ...loadedSlots.map((slot) => slot[id].transformed.path),
              ];
              if (loadedSlotCodes.length) {
                console.debug('[SLOTS][GOOGLEADSPREBID][LOAD] pbjs.setTargetingForGPTAsync(...)', bids);
                pbjs.setTargetingForGPTAsync(loadedSlotCodes);
              }
              biddersCallback();
            } catch (e) {
              console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
              newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
                command: '[GOOGLEADSPREBIDLOAD][BIDSBACKHANDLER]',
                message: (e as Error).message,
              });
              throw e;
            }
          },
        });
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSPREBID][LOAD]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[GOOGLEADSPREBIDLOAD]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADSPREBID][LOAD] pbjs not defined`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[GOOGLEADSPREBIDLOAD]' });
    throw new Error('[SLOTS][GOOGLEADSPREBID][LOAD] pbjs not defined');
  }
};

export const remove = (slots: GoogleAdsSlot[] = []) => {
  console.info('[SLOTS][GOOGLEADSPREBID][REMOVE]', slots);
  return PrebidV2.remove(
    slots.filter((slot) => slot[id].config.prebid.enabled).map((slot) => slot[id].transformed.path)
  );
};

export const setPageTargeting = (params: TargetingParams): void => {
  console.info('[SLOTS][GOOGLEADSPREBID][SETPAGETARGETING]', params);
  PrebidV2.setPageTargeting(params);
};

// eslint-disable-next-line import/no-default-export
export default {
  init,
  dependencies,
  loaded,
  transform,
  create,
  load,
  remove,
};
