import * as State from 'BaxterScript/version/web/core/State';
import { Config } from 'BaxterScript/types/Config';
import { Features } from 'BaxterScript/version/web/config/Features';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import { Debounce } from 'BaxterScript/helper/event/Debounce';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.breakpointRefresh;
  return (
    !(
      (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
      (settings && Object.values(settings).some((item) => !!item?.enabled))
    ) || !baxterV2Enabled(config)
  );
};

const getSettings = (pageId, containerId, slotId) => {
  const settings = globalThis.Baxter.config.slots.settings?.breakpointRefresh;
  return globalThis.Baxter.context.configurationService.getById(settings, pageId, containerId, slotId);
};

const isEnabledForSlot = (pageId, containerId, slotId) => {
  const settings = getSettings(pageId, containerId, slotId);
  return settings && settings.enabled;
};

const refreshSlots = async (
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  if (State.getBreakpoints().length && State.setBreakpointAndDeviceSize()) {
    const slotsToLoad = Object.values(State.getContainers())
      .filter(
        (container) =>
          container.state.slot &&
          isEnabledForSlot(container.state.slot.pageId, container.state.slot.containerId, container.state.slot.id)
      )
      .map((container) => ({
        container,
        loadable: setSlot(
          Features.BREAKPOINT_REFRESH,
          container.state.slot!.pageId,
          container,
          container.state.slot!.params
        ),
      }))
      .filter((containerWithLoadable) => containerWithLoadable.loadable)
      .map((containerWithLoadable) => containerWithLoadable.container.state.slot!);
    await ProviderV2.load(Features.BREAKPOINT_REFRESH, slotsToLoad);
  }
};

const bootstrap = (
  setSlot: (source: string, pageId: string, container: ContainerType, params: TargetingParams) => boolean
) => {
  window.addEventListener(
    'resize',
    Debounce(async () => {
      try {
        console.info('[SLOTS][BREAKPOINTREFRESH][RESIZE]');
        await refreshSlots(setSlot);
      } catch (e) {
        console.error('[SLOTS][BREAKPOINTREFRESH][RESIZE]', e);
        newRelicMetrics.reportError(NewRelicError.BREAKPOINT_REFRESH_HANDLE_RESIZE_ERROR, {
          message: (e as Error).message,
        });
      }
    }, 300)
  );
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  getSettings,
  isEnabledForSlot,
  refreshSlots,
};
