import { Providers } from 'BaxterScript/version/web/config/Providers';
import {
  GoogleImaConfig,
  GoogleImaPrebidBiddersConfig,
  GoogleImaPrebidConfig,
} from 'BaxterScript/types/ProviderSettings/GoogleIma';
import { Config, GoogleAdsProviderConfig } from 'BaxterScript/types/Config';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import {
  GoogleAdsConfig,
  GoogleAdsPrebidBiddersConfig,
  GoogleAdsPrebidConfig,
} from 'BaxterScript/types/ProviderSettings/GoogleAds';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';

const GOOGLEADSID = Providers.GOOGLE_ADS;
const GOOGLEIMAID = Providers.GOOGLE_IMA;

const excludeForProvider = (config: Config, id: string) => {
  const providerSettings = (config.slots?.providerSettings?.[id] ?? {}) as GoogleAdsConfig | GoogleImaConfig;
  const prebidSettings = providerSettings.prebid;
  return !(
    (prebidSettings?._ && Object.values(prebidSettings._).some((item) => item?.enabled === true)) ||
    (prebidSettings && Object.values(prebidSettings).some((item) => item?.enabled === true))
  );
};

export const webpackExclude = (config: Config) =>
  (excludeForProvider(config, GOOGLEADSID) && excludeForProvider(config, GOOGLEIMAID)) || !baxterV2Enabled(config);

export const init = () => {
  console.info('[SLOTS][PREBID][INIT]');
  globalThis.pbjs = globalThis.pbjs || {};
  globalThis.pbjs.que = globalThis.pbjs.que || [];
};

export const dependencies = () => {
  console.info('[SLOTS][PREBID][DEPENDENCIES]');
  const version = globalThis.Baxter.config.providers?.google?.prebid?.version;
  if (version) {
    return [
      {
        id: 'prebid',
        url: `${globalThis.Baxter.config.cdnDomain}/_assets/prebid/${version}.js`,
      },
    ];
  }
  return [];
};

const mapCurrencySettings = (currencySettings: Record<string, unknown> = {}) => {
  console.info('[SLOTS][PREBID][MAPCURRENCYSETTINGS]', currencySettings);
  const mappedCurrencySettings = currencySettings || {};
  if (Array.isArray(currencySettings.bidderCurrencyDefault)) {
    mappedCurrencySettings.bidderCurrencyDefault = Object.fromEntries(
      currencySettings.bidderCurrencyDefault.map((item) => [item.bidder, item.currency])
    );
  }
  if (Array.isArray(currencySettings.defaultRates)) {
    mappedCurrencySettings.defaultRates = Object.fromEntries(
      currencySettings.defaultRates.map((item) => [
        item.name,
        Object.fromEntries((item.rates || []).map((subItem) => [subItem.currency, subItem.rate])),
      ])
    );
  }
  return mappedCurrencySettings;
};

function createPrebidConfig(prebidSettings) {
  console.info('[SLOTS][PREBID][CREATEPREBIDCONFIG]', prebidSettings);
  const modules = prebidSettings.settings?.modules || [];
  if (globalThis.prebidCurrencyConfig) {
    newRelicMetrics.reportMetric(NewRelicMetric.PREBID_CURRENCY_CONFIG);
  }
  const currency = globalThis.prebidCurrencyConfig || mapCurrencySettings(prebidSettings.currency);
  const buckets = prebidSettings.settings?.buckets;
  const timeout = prebidSettings.settings?.timeout;
  const prebidConfig = {
    currency,
    bidderTimeout: timeout,
    enableSendAllBids: true,
    priceGranularity: { buckets },
    userSync: {
      iframeEnabled: true,
      filterSettings: {
        iframe: {
          bidders: '*',
          filter: 'include',
        },
      },
    },
    usePrebidCache: true,
    cache: {
      url: 'https://prebid.adnxs.com/pbc/v1/cache',
    },
  } as Record<string, unknown>;

  if (modules.includes('consentManagement')) {
    prebidConfig.consentManagement = {
      cpmApi: 'iab',
      timeout: typeof globalThis.gdprAppliesGlobally === 'undefined' ? 500 : 1000,
      allowAuctionWithoutConsent: true,
    };
  }
  return prebidConfig;
}

let alreadyLoaded = false;

const createBidderSettings = (prebidSettings, pbjs) => {
  console.info('[SLOTS][PREBID][CREATEBIDDERSETTINGS]', prebidSettings);
  const bidderSettings = {} as Record<
    string,
    { bidCpmAdjustment: (bidCpm: number) => number; storageAllowed: boolean }
  >;
  const { bidders } = prebidSettings;
  Object.keys(bidders).forEach((bidder) => {
    const bidderConfig = bidders[bidder] || {};
    if (bidderConfig.alias) {
      pbjs.aliasBidder(bidderConfig.alias, bidder);
    }
    bidderSettings[bidder] = {
      bidCpmAdjustment: (bidCpm) => {
        const bid = bidCpm * bidderConfig.bidRate;
        return bid <= bidderConfig.bidFloor ? 0 : bid;
      },
      storageAllowed: true,
    };
  });
  return bidderSettings;
};

export const loaded = () => {
  console.info('[SLOTS][PREBID][LOADED]');
  if (alreadyLoaded) {
    return;
  }
  alreadyLoaded = true;
  const providerSettings = globalThis.Baxter.config.providers[GOOGLEADSID] || {};
  const prebidSettings = providerSettings.prebid || {};
  const { pbjs } = window;
  if (pbjs) {
    pbjs.que.push(() => {
      try {
        const prebidConfig = createPrebidConfig(prebidSettings);
        pbjs.setConfig(prebidConfig);
        pbjs.bidderSettings = createBidderSettings(prebidSettings, pbjs);
      } catch (e) {
        console.error('[SLOTS][PREBID][LOADED]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[PREBIDLOADED]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][PREBID][LOADED]`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[PREBIDLOADED]' });
  }
};

export const transform = (slotConfig: GoogleImaPrebidConfig | GoogleAdsPrebidConfig): number[][] => {
  console.info('[SLOTS][PREBID][TRANSFORM]', slotConfig);
  return (slotConfig.sizes || [])
    .map((size) => (size.split('x').length === 2 ? size.split('x').map(Number) : null))
    .filter(Boolean) as number[][];
};

const getParams = (bidderSetting) => {
  const ignoreKeys = ['targeting', 'biddersFloorPriceFields'];
  const params = {};
  Object.keys(bidderSetting).forEach((key) => {
    if (!ignoreKeys.includes(key)) params[key] = bidderSetting[key];
  });

  return params;
};

const getBidParameter = (bidder, slotBidderConfig, targeting) => {
  if (slotBidderConfig.targeting && slotBidderConfig.targeting.strategy === 'copy') {
    // eslint-disable-next-line no-param-reassign
    slotBidderConfig[slotBidderConfig.targeting.field] = targeting;
  }
  const params = getParams(slotBidderConfig);
  return { bidder, params };
};

export const getBids = (
  targeting: TargetingParams,
  providerConfig?: GoogleAdsProviderConfig,
  bidders?: GoogleImaPrebidBiddersConfig | GoogleAdsPrebidBiddersConfig
) => {
  console.info('[SLOTS][PREBID][GETBIDS]', bidders, targeting);
  const providerSettings = (providerConfig || {}) as GoogleAdsProviderConfig;
  const prebidSettings = providerSettings.prebid || { bidders: {} };
  const slotBidders = bidders || {};
  const bidsByCodeType: Record<string, unknown[]> = {};
  Object.keys(slotBidders).forEach((bidder) => {
    const slotBidder = slotBidders[bidder];
    const bidderSettings = prebidSettings.bidders[bidder];
    if (bidderSettings && typeof bidderSettings === 'object') {
      const bidderCodeType = bidderSettings.codeType || 'path';
      (Array.isArray(slotBidder) ? slotBidder : [slotBidder]).forEach((slotBidderConfig) => {
        const bids = bidsByCodeType[bidderCodeType];
        if (!bids) bidsByCodeType[bidderCodeType] = [];
        bidsByCodeType[bidderCodeType].push(getBidParameter(bidder, slotBidderConfig, targeting));
      });
    }
  });

  return bidsByCodeType;
};

export const remove = (paths: string[]): void => {
  console.info('[SLOTS][PREBID][REMOVE]', paths);
  if (globalThis.pbjs) {
    globalThis.pbjs.que.push(() => {
      try {
        globalThis.pbjs.removeAdUnit(paths);
      } catch (e) {
        console.error('[SLOTS][PREBID][REMOVE]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[PREBIDREMOVE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][PREBID][REMOVE] pbjs not defined`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[PREBIDREMOVE]' });
    throw new Error('[SLOTS][PREBID][REMOVE] pbjs not defined');
  }
};

export const setPageTargeting = ({
  dfp_user_id: dfpUserId,
  user_id: userId,
  user_email_sha256: sha256Hash,
}: TargetingParams) => {
  console.info('[SLOTS][PREBID][SETPAGETARGETING]');
  if (globalThis.pbjs) {
    globalThis.pbjs.que.push(() => {
      try {
        const config = {
          ortb2: { user: { ext: { eids: [{ source: window.location.hostname, uids: [] as unknown[] }] } } },
        };
        const ownUsersUids = config.ortb2.user.ext.eids[0].uids;
        if (dfpUserId) {
          ownUsersUids.push({ id: dfpUserId, atype: 1, ext: { stype: 'ppuid', persistence: 'http' } });
        }
        if (userId) {
          ownUsersUids.push({ id: userId, atype: 3, ext: { stype: 'ppuid', persistence: 'js' } });
        }
        if (sha256Hash) {
          ownUsersUids.push({ id: sha256Hash, atype: 3, ext: { stype: 'hemsha256' } });
        }
        config.ortb2.user.ext.eids = config.ortb2.user.ext.eids.filter((eid) => eid && eid.uids.length);
        const providerSettings = globalThis.Baxter.config.providers[GOOGLEADSID] || {};
        const prebidSettings = providerSettings.prebid || {};
        if (ownUsersUids.length && prebidSettings.bidders) {
          globalThis.pbjs.setBidderConfig({ bidders: Object.keys(prebidSettings.bidders), config }, true);
        }
      } catch (e) {
        console.error('[SLOTS][PREBID][SETPAGETARGETING]', e);
        newRelicMetrics.reportError(NewRelicError.PREBID_QUE_ERROR, {
          command: '[PREBIDSETPAGETARGETING]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][PREBID][SETPAGETARGETING] pbjs not defined`);
    newRelicMetrics.reportError(NewRelicError.PREBID_NO_PBJS, { command: '[PREBIDSETPAGETARGETING]' });
    throw new Error('[SLOTS][PREBID][SETPAGETARGETING] pbjs not defined');
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  init,
  dependencies,
  loaded,
  transform,
  getBids,
  remove,
  setPageTargeting,
};
