import * as State from 'BaxterScript/version/web/core/State';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as ProviderV2 from 'BaxterScript/version/web/core/ProviderV2';
import { Config } from 'BaxterScript/types/Config';
import { baxterV2Enabled } from 'BaxterScript/version/web/BaxterV2Enabled';

export const webpackExclude = (config: Config): boolean => !config.app?.consent?.enabled || !baxterV2Enabled(config);

let setCmd: (() => Promise<void>) | undefined;

const removeLegacyCookie = () => {
  const name = 'OTAdditionalConsentString';
  if (Cookie.get(name)) {
    Cookie.remove(name);
  }
};

const bootstrap = () => {
  removeLegacyCookie();
};

const setUserConsent = async () => {
  try {
    console.info('[SLOTS][CONSENT][SETUSERCONSENT]');
    State.setUserConsentResolved();
    if (globalThis.OptanonActiveGroups || globalThis.OnetrustActiveGroups) {
      if (globalThis.OptanonActiveGroups?.match(/STACK42,/) || globalThis.OnetrustActiveGroups?.match(/C0004,/)) {
        newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_DONT_RESTRICT_DATA_PROCESSING);
        ProviderV2.consent(false);
      } else {
        newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_RESTRICT_DATA_PROCESSING);
        ProviderV2.consent(true);
      }
    } else {
      console.debug('[SLOTS][CONSENT][SETUSERCONSENT] missing active groups');
      newRelicMetrics.reportMetric(NewRelicMetric.CONSENT_MISSING_ACTIVE_GROUPS);
      ProviderV2.consent(true);
    }
    if (setCmd) {
      const cmd = setCmd;
      setCmd = undefined;
      await cmd();
    }
  } catch (error) {
    console.error('[SLOTS][CONSENT][SETUSERCONSENT]', error);
    newRelicMetrics.reportError(NewRelicError.CONSENT_SET_USER_CONSENT, { message: (error as Error).message });
  }
};

const delaySet = (cmd) => {
  console.info('[SLOTS][CONSENT][DELAYSET]', cmd);
  if (!State.isUserConsentResolved()) {
    setCmd = cmd;
    return true;
  }
  return false;
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  setUserConsent,
  delaySet,
};
